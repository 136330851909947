import {SIGNUP, SIGNUP_CLEAR, SIGNUP_ERROR, SIGNUP_SUCCESS} from "../../../../constants";

export const clearSignup = () => {
  return {
    type: SIGNUP_CLEAR
  }
};

export const signup = (data) => (dispatch, gs, api) => {
  dispatch(signupAttempt());

  return api.contact(data)
    .then((success) => dispatch(signupSuccess(success)))
    .catch((err) => dispatch(signupError(err)));
};

const signupAttempt = () => {
  return {
    type: SIGNUP
  }
};

const signupSuccess = (success) => {
  return {
    type: SIGNUP_SUCCESS,
    success
  }
};

const signupError = (error) => {
  return {
    type: SIGNUP_ERROR,
    error
  }
};
