import Axios from 'axios'
import {ERROR_BAD_NETWORK} from "../constants";

export default class Api {
    instance;

    constructor(config) {
        this.instance = Axios.create(config);
    }

    fetchRequest = (url, requestParam, bodyParams, method = 'GET', headers = null) => {
        headers = {
            ...headers,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://backoff.tiptaux.com',
            'Access-Control-Allow-Headers': 'POST, OPTIONS, GET'
        };

        return this.instance({
            method: method,
            url: url,
            data: bodyParams,
            params: requestParam,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    console.log('Error: ', error.response);
                    throw error.response;
                }
                throw ERROR_BAD_NETWORK;
            });
    };

    contact = (data) => {
        const url = 'https://backoff.tiptaux.com/api/v1/chatroom/message';
        return this.fetchRequest(url, null, data, 'POST');
    };
};
