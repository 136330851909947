
export const isEmptyString = (input) => {
  return !input || input.trim().length === 0;
};

export const isValidEmail = (input) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(input.toLowerCase());
};

export const isValidPhoneNumber = (input) => {
  if(input.indexOf('+33') !== -1) {
    input = input.replace('+33', '0');
  }
  // eslint-disable-next-line
  const re = /^0[1-7]\d{8}$/;
  return re.test(input);
};