export const ERROR_UNKNOWN = 'ERROR_UNKNOWN';
export const ERROR_BAD_NETWORK = 'ERROR_BAD_NETWORK';

// HTTP Codes
export const HTTP_CODE_FORBIDDEN = 403;
export const HTTP_CODE_LOCKED = 423;
export const HTTP_CODE_BAD_GATEWAY = 502;

// Actions

// Signup
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_CLEAR = 'SIGNUP_CLEAR';
