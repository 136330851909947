import Axios from "axios";
import React, {Component} from "react";
import Container from "@material-ui/core/Container/Container";

import Navbar from "../../../common/navbar";
import {getLocale} from "../../../../utils/global-utils";

class TermsPage extends Component {

  constructor(props){
    super(props);

    this.state = {
      terms : null
    };
  }

  componentDidMount(){
    Axios.create({}).get(`/terms-${getLocale()}.html`)
      .then((response) => {
        this.setState({
          terms: { __html: response.data }
        });
      })
  }

  render(){
    const terms = this.state.terms;
    return (
      <div className="page">
        <Navbar/>
        <div className="content">
          <Container maxWidth="md">
            <div dangerouslySetInnerHTML={terms}/>
          </Container>
        </div>
      </div>
    )
  }

}

export default TermsPage;