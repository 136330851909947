import React, {Component} from "react";
import {Link as RouterLink } from "react-router-dom";
import {defineMessages, FormattedMessage} from "react-intl";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Lottie from "lottie-web";

import Navbar from "../../../common/navbar";

import introVideoThumb from "../../../../assets/img/thumbs/home_intro_thumb.png";
import {useScrollPercentage} from "react-scroll-percentage";
import {withRouter} from "react-router";

const messages = defineMessages({
  title: {
    id: "home.title",
    defaultMessage: "Take the advantage with our expert brokers"
  },
  intro: {
    id: "home.intro",
    defaultMessage: "Discover our service that will make your life easy. The specialist you need and a brand new digital hub, simple and intuitive to handle everything."
  },
  subscribeButton: {
    id: "home.subscribe",
    defaultMessage: "Subscribe"
  },
  stepsTitle: {
    id: "home.steps.title",
    defaultMessage: "5 easy steps"
  },
  stepOne: {
    id: "home.stepOne",
    defaultMessage: "You sign up here"
  },
  stepTwo: {
    id: "home.stepTwo",
    defaultMessage: "Our experts contact you in less than 12h to talk about your project"
  },
  stepThree: {
    id: "home.stepThree",
    defaultMessage: "We setup your project with the best rate on the market"
  },
  stepFour: {
    id: "home.stepFour",
    defaultMessage: "You sign your real estate loan from the Website"
  },
  stepFive: {
    id: "home.stepFive",
    defaultMessage: "You become a real estate owner in no time"
  },
  tiptauxAnchor: {
    id: "home.tiptauxAnchor",
    defaultMessage: "tiptaux it's"
  },
  expertsSectionTitle: {
    id: "home.experts.title",
    defaultMessage: "Experts at your service"
  },
  expertsSectionDescription: {
    id: "home.experts.description",
    defaultMessage: "We are experts on finance and real estate for enterprise and private accounts. We will guide you through every process to buy the real estate of your dream."
  },
  loansSectionTitle: {
    id: "home.loans.title",
    defaultMessage: "Loans for your specific needs"
  },
  loansSectionDescription: {
    id: "home.loans.description",
    defaultMessage: "We build you a project specially for your needs and we negotiate with the banks to find the best deals. Every project is designed for the customer."
  },
  otherServicesSectionTitle: {
    id: "home.otherServices.title",
    defaultMessage: "The best possible deals... and even more !"
  },
  otherServicesSectionDescription: {
    id: "home.otherServices.description",
    defaultMessage: "We know that it's not all about finding the best loan rates, and that's why we also handle loan insurances, from the start until the end."
  },
  platformSectionTitle: {
    id: "home.platform.title",
    defaultMessage: "Intuitive and simple Website"
  },
  platformSectionDescription: {
    id: "home.platform.description",
    defaultMessage: "Our website & mobile app handle all your documents effortlessly, and allow you to manage your project wherever you are. It also allows you to keep in touch with our experts."
  },
  subscribeTitle: {
    id: "home.subscribe.title",
    defaultMessage: "Let's go, sign in !"
  },
  commitmentsTitle: {
    id: "home.commitments.title",
    defaultMessage: "Our commitments"
  },
  commitmentOneTitle: {
    id: "home.commitments.one.title",
    defaultMessage: "Serenity"
  },
  commitmentOneDescription: {
    id: "home.commitments.one.description",
    defaultMessage: "Guaranteed serenity, because you shouldn't handle all that process alone"
  },
  commitmentTwoTitle: {
    id: "home.commitments.two.title",
    defaultMessage: "Trust"
  },
  commitmentTwoDescription: {
    id: "home.commitments.two.description",
    defaultMessage: "Because privacy is our main concern, we protect all your data"
  },
  commitmentThreeTitle: {
    id: "home.commitments.three.title",
    defaultMessage: "Reactivity"
  },
  commitmentThreeDescription: {
    id: "home.commitments.three.description",
    defaultMessage: "First appointment within 12h. For many of you, this represent a life time investment, and for this reason, you can reach us at any time."
  },
  commitmentFourTitle: {
    id: "home.commitments.four.title",
    defaultMessage: "Transparency"
  },
  commitmentFourDescription: {
    id: "home.commitments.four.description",
    defaultMessage: "No last minute charges, our pricings are fixed from the start. All charges / fees will stay as clear as it can be."
  },
  footerAboutTitle: {
    id: "home.footer.about.title",
    defaultMessage: "About"
  },
  footerAboutUs: {
    id: "home.footer.about.aboutUs",
    defaultMessage: "About us"
  },
  footerAboutTeam: {
    id: "home.footer.about.team",
    defaultMessage: "Team"
  },
  footerAboutNews: {
    id: "home.footer.about.news",
    defaultMessage: "Articles"
  },
  footerAboutJobs: {
    id: "home.footer.about.jobs",
    defaultMessage: "Jobs"
  },
  footerAboutContactUs: {
    id: "home.footer.about.contactUs",
    defaultMessage: "Contact us"
  },
  footerLegalTitle: {
    id: "home.footer.legal.title",
    defaultMessage: "Legal"
  },
  footerLegalMentions: {
    id: "home.footer.legal.mentions",
    defaultMessage: "Mentions"
  },
  footerLegalTermsOfUse: {
    id: "home.footer.legal.tou",
    defaultMessage: "Terms of use"
  },
  footerLegalPrivacy: {
    id: "home.footer.legal.privacy",
    defaultMessage: "Privacy"
  },
  footerFAQTitle: {
    id: "home.footer.faq.title",
    defaultMessage: "FAQ"
  },
  footerFAQQuestionOne: {
    id: "home.footer.faq.question.one",
    defaultMessage: "What is a real estate loan offer ?"
  },
  footerFAQQuestionTwo: {
    id: "home.footer.faq.question.two",
    defaultMessage: "How to check pre-deal contract ?"
  },
  footerFAQQuestionThree: {
    id: "home.footer.faq.question.three",
    defaultMessage: "What are the common errors to avoid ?"
  },
  footerFAQQuestionFour: {
    id: "home.footer.faq.question.four",
    defaultMessage: "What to compare loans rates ?"
  }
});

class HomePage extends Component {

  componentDidMount(){

    this.animationIntro = Lottie.loadAnimation({
      container: document.getElementById("intro"),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/home_intro.json',
      rendererSettings: {
        progressiveLoad: true,
        preserveAspectRatio: 'xMinYMin meet'
      }
    });

    this.animationIntro.addEventListener('DOMLoaded', () => {
      document.getElementById("intro-placeholder").style.display = 'none';
    });

    this.animationSection1 = Lottie.loadAnimation({
      container: document.getElementById("animation-section-1"),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/home_section_1.json',
      rendererSettings: {
        progressiveLoad: true,
        preserveAspectRatio: 'xMinYMin meet'
      }
    });

    this.animationSection2 = Lottie.loadAnimation({
      container: document.getElementById("animation-section-2"),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/home_section_2.json',
      rendererSettings: {
        progressiveLoad: true,
        preserveAspectRatio: 'xMinYMin meet'
      }
    });

    this.animationSection3 = Lottie.loadAnimation({
      container: document.getElementById("animation-section-3"),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/home_section_3.json',
      rendererSettings: {
        progressiveLoad: true,
        preserveAspectRatio: 'xMinYMin meet'
      }
    });

    this.animationSection4 = Lottie.loadAnimation({
      container: document.getElementById("animation-section-4"),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/home_section_4.json',
      rendererSettings: {
        progressiveLoad: true,
        preserveAspectRatio: 'xMinYMin meet'
      }
    });
  }

  componentWillUnmount(){

    this.animationIntro.destroy();
    this.animationSection1.destroy();
    this.animationSection2.destroy();
    this.animationSection3.destroy();
    this.animationSection4.destroy();
  }

  render(){
    return(
      <div className="page home">
        <Navbar/>
        <div className="content">
          <Title/>
          <Intro/>
          <StepsHorizontal/>
          <SectionExperts/>
          <SectionLoans/>
          <SectionOtherServices/>
          <SectionPlatform/>
          <SubscribeBanner/>
          <Commitments/>
          <Footer/>
        </div>
      </div>
    );
  }
}

const Title = () => {
  return (
    <section className="title">
      <Container maxWidth="md">
        <div className="section-title">
          <FormattedMessage { ...messages.title }/>
        </div>
      </Container>
    </section>
  )
};

const Intro = withRouter(({ history }) => {
  return (
    <section className="intro">
      <Container maxWidth="md">
        <div id="intro">
          <img alt="placeholder" id="intro-placeholder" src={introVideoThumb}/>
        </div>
        <div className="text">
          <FormattedMessage { ...messages.intro }/>
        </div>
        <button className="tt-theme"
                onClick={ () => history.push('/signup') }>
          <FormattedMessage { ...messages.subscribeButton }/>
        </button>
      </Container>
    </section>
  )
});

const StepsHorizontal = () => {

  const [ref, percentage] = useScrollPercentage({
    threshold: 0.15,
  });

  return (
    <section ref={ref}
             className="steps">
      <Container maxWidth="md">
        <div className="section-title">
          <FormattedMessage { ...messages.stepsTitle }/>
        </div>
        <ul className="tt-theme step-list">
          <li className={`step ${ percentage > 0.15 ? 'active' : '' }`}>
            <span className="number">1</span>
            <div className="progress"/>
            <div className="description">
              <FormattedMessage { ...messages.stepOne }/>
            </div>
          </li>
          <li className={`step ${ percentage > 0.28 ? 'active' : '' }`}>
            <span className="number">2</span>
            <div className="progress"/>
            <div className="description">
              <FormattedMessage { ...messages.stepTwo }/>
            </div>
          </li>
          <li className={`step ${ percentage > 0.41 ? 'active' : '' }`}>
            <span className="number">3</span>
            <div className="progress"/>
            <div className="description">
              <FormattedMessage { ...messages.stepThree }/>
            </div>
          </li>
          <li className={`step ${ percentage > 0.54 ? 'active' : '' }`}>
            <span className="number">4</span>
            <div className="progress"/>
            <div className="description">
              <FormattedMessage { ...messages.stepFour }/>
            </div>
          </li>
          <li className={`step ${ percentage > 0.67 ? 'active' : '' }`}>
            <span className="number">5</span>
            <div className="progress"/>
            <div className="description">
              <FormattedMessage { ...messages.stepFive }/>
            </div>
          </li>
        </ul>
        <div className="section-title">
          <FormattedMessage { ...messages.tiptauxAnchor }/>
        </div>
      </Container>
    </section>
  )
};

const SECTION_VISIBLE_START = 0.2;
const SECTION_VISIBLE_END = 0.8;

const SectionExperts = () => {

  const [ref, percentage] = useScrollPercentage();

  return (
    <section ref={ref} className={ `base picto light ${ percentage > SECTION_VISIBLE_START && percentage < SECTION_VISIBLE_END ? 'visible' : '' }` }>
      <Container maxWidth="md">
        <div className="section-title-secondary">
          <FormattedMessage { ...messages.expertsSectionTitle }/>
        <div className="section-animation" id="animation-section-1"/>
        {/*<HomePicto svg={expertsPicto}/>*/}
        </div>
        <div className="text">
          <FormattedMessage { ...messages.expertsSectionDescription }/>
        </div>
      </Container>
    </section>
  )
};

const SectionLoans = () => {

  const [ref, percentage] = useScrollPercentage();

  return (
    <section ref={ref} className={ `base picto ${ percentage > SECTION_VISIBLE_START && percentage < SECTION_VISIBLE_END ? 'visible' : '' }` }>
      <Container maxWidth="md">
        <div className="section-title-secondary">
          <FormattedMessage { ...messages.loansSectionTitle }/>
        </div>
        <div className="section-animation" id="animation-section-2"/>
        {/*<HomePicto svg={loansPicto}/>*/}
        <div className="text">
          <FormattedMessage { ...messages.loansSectionDescription }/>
        </div>
      </Container>
    </section>
  )
};

const SectionOtherServices = () => {

  const [ref, percentage] = useScrollPercentage();

  const visible = percentage > SECTION_VISIBLE_START && percentage < SECTION_VISIBLE_END;

  return (
    <section ref={ref} className={ `base light ${ visible ? 'visible' : '' }` }>
      {/*<div className="animation left">*/}
        {/*<div id="animation-left"/>*/}
      {/*</div>*/}
      <Container maxWidth="md">
        <div className="section-title-secondary">
          <FormattedMessage { ...messages.otherServicesSectionTitle }/>
        </div>
        <div className="section-animation" id="animation-section-3"/>
        <div className="text">
          <FormattedMessage { ...messages.otherServicesSectionDescription }/>
        </div>
      </Container>
      {/*<div className="animation right">*/}
        {/*<div id="animation-right"/>*/}
      {/*</div>*/}
    </section>
  )
};

const SectionPlatform = () => {

  const [ref, percentage] = useScrollPercentage();

  return (
    <section ref={ref} className={ `base ${ percentage > SECTION_VISIBLE_START && percentage < SECTION_VISIBLE_END ? 'visible' : '' }` }>
      <Container maxWidth="md">
        <div className="section-title-secondary">
          <FormattedMessage { ...messages.platformSectionTitle }/>
        </div>
        <div className="section-animation" id="animation-section-4"/>
        
        <div className="text">
          <FormattedMessage { ...messages.platformSectionDescription }/>
        </div>
      </Container>
    </section>
  )
};

const SubscribeBanner = withRouter(({ history }) => {

  const [ref, percentage] = useScrollPercentage();

  return (
    <section ref={ref} className={ `subscribe ${percentage > SECTION_VISIBLE_START && percentage < SECTION_VISIBLE_END ? 'visible' : '' }` }>
      <Container className="section-content"
                 maxWidth="md">
        <div className="section-title">
          <FormattedMessage { ...messages.subscribeTitle }/>
        </div>
        <span className="arrow">&#8594;</span>
        <button className="tt-theme"
                onClick={ () => history.push('/signup') }>
          <FormattedMessage { ...messages.subscribeButton }/>
        </button>
      </Container>
    </section>
  )
});

const Commitments = () => {
  return (
    <section className="commitments light">
      <Container maxWidth="md">
        <div className="section-title">
          <FormattedMessage { ...messages.commitmentsTitle }/>
        </div>
        <Grid className="commitment-grid" container>
          <Grid className="commitment"
                item
                sm={6}
                xs={12}>
            <div className="badge">1</div>
            <div className="section-title">
              <FormattedMessage { ...messages.commitmentOneTitle }/>
            </div>
            <div className="description">
              <FormattedMessage { ...messages.commitmentOneDescription }/>
            </div>
          </Grid>
          <Grid className="commitment"
                item
                sm={6}
                xs={12}>
            <div className="badge">2</div>
            <div className="section-title">
              <FormattedMessage { ...messages.commitmentTwoTitle }/>
            </div>
            <div className="description">
              <FormattedMessage { ...messages.commitmentTwoDescription }/>
            </div>
          </Grid>
          <Grid className="commitment"
                item
                sm={6}
                xs={12}>
            <div className="badge">3</div>
            <div className="section-title">
              <FormattedMessage { ...messages.commitmentThreeTitle }/>
            </div>
            <div className="description">
              <FormattedMessage { ...messages.commitmentThreeDescription }/>
            </div>
          </Grid>
          <Grid className="commitment"
                item
                sm={6}
                xs={12}>
            <div className="badge">4</div>
            <div className="section-title">
              <FormattedMessage { ...messages.commitmentFourTitle }/>
            </div>
            <div className="description">
              <FormattedMessage { ...messages.commitmentFourDescription }/>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
};

const Footer = () => {
  return (
    <footer className="tt-theme">
      <Container maxWidth="md">
        <Grid container>

          <Grid className="footer-section"
                item
                sm={4}
                xs={12}>
            <div className="title">
              <FormattedMessage { ...messages.footerAboutTitle }/>
            </div>
            <ul className="tt-theme">
              {/*<li>*/}
                {/*<RouterLink className="tt-theme"*/}
                            {/*to="/about">*/}
                  {/*<FormattedMessage { ...messages.footerAboutUs }/>*/}
                {/*</RouterLink>*/}
              {/*</li>*/}
              {/*<li>*/}
                {/*<RouterLink className="tt-theme"*/}
                            {/*to="/team">*/}
                  {/*<FormattedMessage { ...messages.footerAboutTeam }/>*/}
                {/*</RouterLink>*/}
              {/*</li>*/}
              {/*<li>*/}
                {/*<RouterLink className="tt-theme"*/}
                            {/*to="/news">*/}
                  {/*<FormattedMessage { ...messages.footerAboutNews }/>*/}
                {/*</RouterLink>*/}
              {/*</li>*/}
              {/*<li>*/}
                {/*<RouterLink className="tt-theme"*/}
                            {/*to="/jobs">*/}
                  {/*<FormattedMessage { ...messages.footerAboutJobs }/>*/}
                {/*</RouterLink>*/}
              {/*</li>*/}
              {/*<li>*/}
                {/*<a className="tt-theme"*/}
                   {/*href="mailto:contact@tiptaux.com">*/}
                  {/*<FormattedMessage { ...messages.footerAboutContactUs }/>*/}
                {/*</a>*/}
              {/*</li>*/}
            </ul>
          </Grid>

          <Grid className="footer-section"
                item
                sm={4}
                xs={12}>
            <div className="title">
              <FormattedMessage { ...messages.footerLegalTitle }/>
            </div>
            <ul className="tt-theme">
              {/*<li>*/}
                {/*<RouterLink className="tt-theme"*/}
                            {/*to="/legal/mentions">*/}
                  {/*<FormattedMessage { ...messages.footerLegalMentions }/>*/}
                {/*</RouterLink>*/}
              {/*</li>*/}
              <li>
                <RouterLink className="tt-theme"
                            to="/terms">
                  <FormattedMessage { ...messages.footerLegalTermsOfUse }/>
                </RouterLink>
              </li>
              {/*<li>*/}
                {/*<RouterLink className="tt-theme"*/}
                            {/*to="/legal/privacy">*/}
                  {/*<FormattedMessage { ...messages.footerLegalPrivacy }/>*/}
                {/*</RouterLink>*/}
              {/*</li>*/}
            </ul>
          </Grid>

          <Grid className="footer-section"
                item
                sm={4}
                xs={12}>
            <div className="title">
              <FormattedMessage { ...messages.footerFAQTitle }/>
            </div>
            <ul className="tt-theme">
              {/*<li>*/}
                {/*<RouterLink className="tt-theme"*/}
                            {/*to="/faq">*/}
                  {/*<FormattedMessage { ...messages.footerFAQQuestionOne }/>*/}
                {/*</RouterLink>*/}
              {/*</li>*/}
              <li>
                <RouterLink className="tt-theme"
                            to="/faq">
                  <FormattedMessage { ...messages.footerFAQQuestionTwo }/>
                </RouterLink>
              </li>
              {/*<li>*/}
                {/*<RouterLink className="tt-theme"*/}
                            {/*to="/faq">*/}
                  {/*<FormattedMessage { ...messages.footerFAQQuestionThree }/>*/}
                {/*</RouterLink>*/}
              {/*</li>*/}
              {/*<li>*/}
                {/*<RouterLink className="tt-theme"*/}
                            {/*to="/faq">*/}
                  {/*<FormattedMessage { ...messages.footerFAQQuestionFour }/>*/}
                {/*</RouterLink>*/}
              {/*</li>*/}
            </ul>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
};

export default HomePage;