import Container from "@material-ui/core/Container/Container";
import React from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {Link as RouterLink } from "react-router-dom";
import {withRouter} from "react-router";

const messages = defineMessages({
  menuLogin: {
    id: "menu.login",
    defaultMessage: "Log In"
  },
  menuSignup: {
    id: "menu.signup",
    defaultMessage: "Sign Up"
  }
});

const Navbar = (props) => {

  const urlContains = (path) => {
    return props.location.pathname.indexOf(path) !== -1;
  };

  return (
    <nav className="tt-theme">
      <Container className="nav-content"
                 maxWidth="lg">
        <RouterLink className="brand" to="/"/>
        <div className="right">
          <div className="menu">
            {/*<RouterLink className={`tt-theme menu-item ${urlContains('/login') ? 'primary' : ''}`} to="/login">*/}
              {/*<FormattedMessage {...messages.menuLogin}/>*/}
            {/*</RouterLink>*/}
            <RouterLink className={`tt-theme menu-item ${urlContains('/signup') ? 'primary' : ''}`} to="/signup">
              <FormattedMessage {...messages.menuSignup}/>
            </RouterLink>
          </div>
        </div>
      </Container>
    </nav>
  )
};

export default withRouter(Navbar);