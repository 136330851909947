import * as PropTypes from "prop-types";
import React, {Component} from "react";
import {Formik} from "formik";
import TextField from "@material-ui/core/TextField";
import {defineMessages, FormattedMessage, injectIntl} from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import {connect} from "react-redux";
import {isEmptyString, isValidEmail, isValidPhoneNumber} from "../../../../utils/validation-utils";
import {clearSignup, signup} from "./signup.actions";


const messages = defineMessages({
    contactLastNameLabel: {
        id: "contact.lastName.label",
        defaultMessage: "Last Name"
    },
    contactFirstNameLabel: {
        id: "contact.firstName.label",
        defaultMessage: "First Name"
    },
    contactEmailLabel: {
        id: "contact.email.label",
        defaultMessage: "Email"
    },
    contactMobilePhoneLabel: {
        id: "contact.mobilePhone.label",
        defaultMessage: "Phone"
    },
    contactMentorLabel: {
        id: "contact.mentor.label",
        defaultMessage: "Mentor"
    },
    contactMessageLabel: {
        id: "contact.message.label",
        defaultMessage: "Message"
    },
    contactSubmitButton: {
        id: "contact.submit.button",
        defaultMessage: "Submit"
    },
    errorRequired: {
        id: "error.requiredField",
        defaultMessage: "Required field"
    },
    errorEmail: {
        id: "error.invalidEmail",
        defaultMessage: "Invalid email"
    },
    errorPhoneNumber: {
        id: "error.invalidPhoneNumber",
        defaultMessage: "Invalid phone number"
    },
    errorServerMessage: {
        id: "error.server.signup",
        defaultMessage: "An error occurred while signing up. Please try again later."
    },
    successMessage: {
        id: "signup.success",
        defaultMessage: "Thank you for filling in this form. We will contact you shortly."
    }
});

const initialValues = {
    last_name: '',
    first_name: '',
    email: '',
    mobile_phone: '',
    mentor: '',
    message: ''
};

const propTypes = {
    clear: PropTypes.func.isRequired,
    signup: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.object,
    success: PropTypes.bool
};

let isSent = 0;


class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        this.captchaRef = React.createRef();
    }

    componentDidMount() {
        this.props.clear();
    }

   set () {
       setTimeout(function(){ window.location.href = ("/") }, 3000);
   }



    handleFormSubmit(values, {resetForm}) {
        const captchaValue = this.captchaRef.current.getValue();

        if (isEmptyString(captchaValue)) {
            isSent = 0;
            this.captchaRef.current.reset();
        }
        else {
            isSent = 1;
            const data = {...values};
            data['g-recaptcha-response'] = captchaValue;
           if (!this.props.signup(data).error){
               resetForm();
               this.set();
           }
        }

    }



    handleFormValidation(values) {
        const errors = {};

        // LastName
        if (isEmptyString(values.last_name)) {
            errors.last_name = messages.errorRequired;
        }
        //FirstName
        if (isEmptyString(values.first_name)) {
            errors.first_name = messages.errorRequired;
        }
        // Email
        if (isEmptyString(values.email)) {
            errors.email = messages.errorRequired;
        }
        else if (!isValidEmail(values.email)) {
            errors.email = messages.errorEmail;
        }

        // Phone number
        if (isEmptyString(values.mobile_phone)) {
            errors.phoneNumber = messages.errorRequired;
        }
        else if (!isValidPhoneNumber(values.mobile_phone)) {
            errors.phoneNumber = messages.errorPhoneNumber;
        }

        // Message
        if (isEmptyString(values.message)) {
            errors.message = messages.errorRequired;
        }

        return errors;
    }

    render() {
        const {intl, error, success} = this.props;
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.handleFormSubmit}
                validate={this.handleFormValidation}
                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      /* and other goodies */
                  }) => {

                    const inputProps = {
                        onBlur: handleBlur
                    };

                    return (
                        <form onSubmit={handleSubmit}>

                            {error &&
                            <div className="tt-theme alert error">
                                <FormattedMessage {...messages.errorServerMessage}/>
                            </div>
                            }

                            {(!error && isSent === 1) &&
                            <div className="tt-theme alert success">
                                <FormattedMessage {...messages.successMessage}/>
                            </div>
                            }

                            <TextField
                                className="tt-theme"
                                id="last_name"
                                name="last_name"
                                label={intl.formatMessage({...messages.contactLastNameLabel})}
                                fullWidth
                                margin="normal"
                                inputProps={inputProps}
                                error={errors.last_name !== undefined}
                                helperText={errors.last_name ? intl.formatMessage({...errors.last_name}) : undefined}
                                onChange={handleChange}
                                value={values.last_name}
                            />
                            <TextField
                                className="tt-theme"
                                id="first_name"
                                name="first_name"
                                label={intl.formatMessage({...messages.contactFirstNameLabel})}
                                fullWidth
                                margin="normal"
                                inputProps={inputProps}
                                error={errors.first_name !== undefined}
                                helperText={errors.first_name ? intl.formatMessage({...errors.first_name}) : undefined}
                                onChange={handleChange}
                                value={values.first_name}
                            />

                            <TextField
                                className="tt-theme"
                                id="email"
                                name="email"
                                label={intl.formatMessage({...messages.contactEmailLabel})}
                                fullWidth
                                margin="normal"
                                inputProps={inputProps}
                                error={errors.email !== undefined}
                                helperText={errors.email ? intl.formatMessage({...errors.email}) : undefined}
                                onChange={handleChange}
                                value={values.email}
                            />

                            <TextField
                                className="tt-theme"
                                id="mobile_phone"
                                name="mobile_phone"
                                label={intl.formatMessage({...messages.contactMobilePhoneLabel})}
                                fullWidth
                                margin="normal"
                                inputProps={inputProps}
                                error={errors.phoneNumber !== undefined}
                                helperText={errors.phoneNumber ? intl.formatMessage({...errors.phoneNumber}) : undefined}
                                onChange={handleChange}
                                value={values.mobile_phone}
                            />

                            <TextField
                                className="tt-theme"
                                id="mentor"
                                name="mentor"
                                label={intl.formatMessage({...messages.contactMentorLabel})}
                                fullWidth
                                margin="normal"
                                inputProps={inputProps}
                                onChange={handleChange}
                                value={values.mentor}
                            />

                            <TextField
                                className="tt-theme"
                                id="message"
                                name="message"
                                label={intl.formatMessage({...messages.contactMessageLabel})}
                                fullWidth
                                margin="normal"
                                multiline
                                inputProps={inputProps}
                                error={errors.message !== undefined}
                                helperText={errors.message ? intl.formatMessage({...errors.message}) : undefined}
                                onChange={handleChange}
                                rows={4}
                                value={values.message}
                            />

                            <div className="recaptcha">
                                <ReCAPTCHA sitekey="6LcVT4kUAAAAABNGeuuDx_6RMdbbaTzUT7EM6c76"
                                           style={{display: 'inline-block'}}
                                           theme="dark"
                                           ref={this.captchaRef}/>
                            </div>

                            <button  className="tt-theme"
                                    type="submit">
                                <FormattedMessage {...messages.contactSubmitButton}/>
                            </button>

                        </form>
                    )
                }}
            </Formik>
        )
    }
}

ContactForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {loading, error} = state.signup;
    return {
        loading,
        error
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clear: () => dispatch(clearSignup()),
        signup: (data) => dispatch(signup(data)),

    }

};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContactForm));

