import React from "react";
import Container from "@material-ui/core/Container";

import ContactForm from "./contact-form";
import Navbar from "../../../common/navbar";
import {defineMessages, FormattedMessage} from "react-intl";

const messages = defineMessages({
  signupTitle: {
    id: "signup.title",
    defaultMessage: "Let's talk !"
  },
  privacyNotice: {
    id: "signup.privacy.notice",
    defaultMessage: "Your data will remain on France located data center.\nYour email and phone number will only be used to contact you after your formal request.\nWe keep your personnal data for a month. After that, it is erased."
  }
});

const SignupPage = () => {
  return (
    <div className="page signup">
      <Navbar/>
      <div className="content">
        <Container maxWidth="sm">
          <div className="section-title">
            <FormattedMessage { ...messages.signupTitle }/>
          </div>
          <ContactForm/>
          <div className="notice">
            <FormattedMessage { ...messages.privacyNotice }/>
          </div>
        </Container>
      </div>
    </div>
  )
};

export default SignupPage;