import React from 'react';
import ReactDOM from 'react-dom';
import {createBrowserHistory} from "history";
import {addLocaleData, IntlProvider} from "react-intl";
import frLocaleData from "react-intl/locale-data/fr";
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, combineReducers} from 'redux';
import {createLogger} from 'redux-logger';
import ThunkMiddleware from 'redux-thunk';
import {routerMiddleware, connectRouter, ConnectedRouter} from 'connected-react-router';


import './index.scss';
import App from './app';
import * as serviceWorker from './serviceWorker';
import Translations from "./i18n/locales/index";
import Reducers from "./reducers";
import Api from "./utils/api";
import {getLocale} from "./utils/global-utils";

// Locale
addLocaleData(frLocaleData);
const locale = getLocale();

// History
const history = createBrowserHistory();


const baseUrl = `https://www.tiptaux.fr`;

const api = new Api({
    baseURL: baseUrl
});

let middlewares = [
    ThunkMiddleware.withExtraArgument(api),
    routerMiddleware(history)
];

const debugLevel = localStorage.getItem('debug');

// Do we authorize debug or not
if (debugLevel !== undefined) {
    const logExceptions = [];

    middlewares.push(createLogger({
        predicate: (getState, action) => logExceptions.indexOf(action.type) === -1
    }));
}

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = createStore(
    combineReducers({
        ...Reducers,
        router: connectRouter(history)
    }),
    applyMiddleware(...middlewares)
);

ReactDOM.render(
    <Provider store={store}>
        <IntlProvider locale={locale}
                      messages={Translations[locale]}>
            <ConnectedRouter history={history}>
                <App/>
            </ConnectedRouter>
        </IntlProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
