import React from 'react';
import {Route, Switch, withRouter, Redirect} from "react-router";

import HomePage from "./components/pages/public/home";
import SignupPage from "./components/pages/public/signup";
import TermsPage from "./components/pages/public/terms";
import FaqPage from "./components/pages/public/faq";

const App = (props) => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage}/>
      <Route exact path="/terms" component={TermsPage}/>
      <Route exact path="/faq" component={FaqPage}/>
      <Route exact path="/signup" component={SignupPage}/>
      <Redirect from="*" to="/"/>
    </Switch>
  );
};

export default withRouter(App);
