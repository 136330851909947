import Navbar from "../../../common/navbar";
import Container from "@material-ui/core/Container/Container";
import {defineMessages, FormattedMessage} from "react-intl";
import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const messages = defineMessages({
  faqTitle: {
    id: "faq.title",
    defaultMessage: "Frequently Asked Questions"
  },


  faq1Question: {
    id: "faq.1.question",
    defaultMessage: "Question 1"
  },
  faq1Answer: {
    id: "faq.1.answer",
    defaultMessage: "Answer 1"
  },


  faq2Question: {
    id: "faq.2.question",
    defaultMessage: "Question 2"
  },
  faq2Answer: {
    id: "faq.2.answer",
    defaultMessage: "Answer 2"
  }
});

const FaqPage = () => {
  return (
    <div className="page faq">
      <Navbar/>
      <div className="content">
        <Container maxWidth="md">
          <h3><FormattedMessage { ...messages.faqTitle }/></h3>

          <ExpansionPanel square={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon/>}>
              <FormattedMessage { ...messages.faq1Question }/>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <FormattedMessage { ...messages.faq1Answer }/>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel square={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon/>}>
              <FormattedMessage { ...messages.faq2Question }/>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <FormattedMessage { ...messages.faq2Answer }/>
            </ExpansionPanelDetails>
          </ExpansionPanel>

        </Container>
      </div>
    </div>
  )
};

export default FaqPage;