import {SIGNUP, SIGNUP_CLEAR, SIGNUP_ERROR, SIGNUP_SUCCESS} from "../../../../constants";

const initialState = {
  loading: false,
  error: null,
  success: false
};

const signupReducer = (state = initialState, action) => {

  if(action.type === SIGNUP_CLEAR){
    return initialState;
  }

  if(action.type === SIGNUP){
    return {
      ...state,
      loading: true,
      error: null,
      success: false
    }
  }

  if(action.type === SIGNUP_SUCCESS){
    return {
      ...state,
      loading: false,
      success: true
    }
  }

  if(action.type === SIGNUP_ERROR){
    return {
      ...state,
      loading: false,
      error: action.error
    }
  }

  return state;
};

export default signupReducer;